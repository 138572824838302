import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Terms = ({ userInfo, setUserInfo }) => {
  const [agreed, setAgreed] = useState(false);
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const handleAgree = () => {
    setAgreed(!agreed);
  };

  const handleSubmit = async () => {
    setSaving(true);
    await axios
      .get(process.env.REACT_APP_API + "/user/terms")
      .then((res) => {
        if (res.data.terms != null) {
          setUserInfo(res.data);
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Unable to submit");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          {userInfo.terms ? (
            <Alert variant="success" className="text-center">
              You agreed to the terms on{" "}
              {new Date(userInfo.terms).toLocaleDateString()}.
            </Alert>
          ) : (
            <Alert variant="info" className="text-center">
              Please review the Catalogue Value Disclaimer
            </Alert>
          )}
          <Card>
            <Card.Header as="h3" className="text-center">
              Los Santos LLC - Catalogue Value Disclaimer
            </Card.Header>
            <Card.Body>
              <p>
                The information provided in this software regarding the
                catalogue value of your trees is intended for informational
                purposes only. It represents an estimate of the potential future
                value of your trees based on available data and calculations.
              </p>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Not a Guarantee:</strong> The catalogue value
                  displayed is not a guarantee of the actual future worth of
                  your trees. It is an approximation based on our research and
                  assumptions, and actual market conditions may vary
                  significantly.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Market Fluctuations:</strong> The value of trees can
                  be influenced by a wide range of factors, including but not
                  limited to market demand, environmental conditions, and
                  unforeseen events. These factors can cause the actual value of
                  your trees to differ from the catalogue value.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>No Investment Advice:</strong> Los Santos LLC (
                  <a href="http://Azucenas.net">Azucenas.net</a>) does not
                  provide financial, investment, or legal advice. The catalogue
                  value is not to be interpreted as investment advice, and it is
                  essential to consult with a qualified professional before
                  making any investment decisions related to your trees.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Limited Liability:</strong> Los Santos LLC (
                  <a href="http://Azucenas.net">Azucenas.net</a>) is not liable
                  for any losses or damages that may occur as a result of
                  relying on the catalogue value or any other information
                  provided in this software feature. Users are responsible for
                  their investment decisions and actions.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Subject to Change:</strong> The catalogue value is
                  subject to change as new information becomes available, and as
                  our research and calculations are updated. It may not reflect
                  real-time market conditions.
                </ListGroup.Item>
              </ListGroup>
              <p className="mt-3">
                By using this software feature, you acknowledge and agree to the
                above terms and disclaimers. Los Santos LLC (
                <a href="http://Azucenas.net">Azucenas.net</a>) is not
                responsible for any decisions made based on the catalogue value
                displayed within the app.
              </p>
              <p>
                If you have any questions or concerns about the catalogue value
                or its implications, please seek professional advice or contact
                our customer support team for clarification.
              </p>
              {!userInfo.terms && (
                <Form className="mt-4">
                  <Form.Group controlId="termsCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="I agree to the terms and conditions."
                      checked={agreed}
                      onChange={handleAgree}
                    />
                  </Form.Group>
                  {saving ? (
                    <Spinner animation="border" variant="success" size="sm" />
                  ) : (
                    <Button
                      variant="primary"
                      className="mt-3"
                      onClick={handleSubmit}
                      disabled={!agreed} // Disable the button until the checkbox is checked
                    >
                      {"Submit"}
                    </Button>
                  )}
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
