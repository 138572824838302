import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import './ClientNav.scss'
import { Alert, Badge, Button, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";

Session.addAxiosInterceptors(axios);

export default function ClientNav({ logout, userInfo }) {
    const history = useHistory()
    useEffect(() => {
    }, [])

    return (
        <div className="Client-Nav">
            <div className="Client-Nav-Top">

                <div>

                </div>
                <hr></hr>
            
                <img id="logo" src="/lasazucenas.png" width="90px"/>
                {/* <img style={{marginBottom: '2em'}} src="lasazucenas.png" width="100px" alt="Las Azucenas" /> */}
                {/* <h4>Las Azucenas</h4> */}

                <p style={{textAlign: 'center'}}>{userInfo.first_name} {userInfo.last_name}</p>


                <hr></hr>
                <div style={{ minWidth: '100%', height: '300px' }} className="d-grid gap-2">
                    <ListGroup variant="flush">
                        <ListGroup.Item onClick={() => history.push('/marketplace')} action variant="light">
                            <img style={{ marginRight: '.5em' }} src="/forsale.svg" alt="market icon" width="30px" /> Marketplace
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/my-trees')} action variant="light">
                            <img style={{ marginRight: '.5em' }} src="/forest.svg" alt="market icon" width="30px" /> My Trees
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/my-documents')} action variant="light">
                            <img style={{ marginRight: '.5em' }} src="/my-docs.svg" alt="market icon" width="30px" /> My Documents
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/faq')} action variant="light">
                            <img style={{ marginRight: '.5em' }} src="/faq.svg" alt="market icon" width="30px" /> FAQ
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/profile')} action variant="light">
                            <img style={{ marginRight: '.5em' }} src="/profile.svg" alt="market icon" width="30px" /> Profile
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/terms')} action variant="light">
                            <img style={{ marginRight: '.5em' }} src="/terms.svg" alt="market icon" width="30px" /> Terms
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => logout()} action variant="success" size="lg">
                            <img style={{ marginRight: '.5em' }} src="/logout.svg" alt="market icon" width="30px" />Logout
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup variant="flush">
                    </ListGroup>

                </div>
            </div>
            {/* <img style={{marginBottom: '2em'}} src="lasazucenas.png" width="100px" alt="Las Azucenas" /> */}

        </div>
    )
}